import React from 'react';
import './Card.scss';
import Button from '../Button/Button';
import { EnumButtonType } from '../../enums/EnumButtonType';
import { EnumButtonSize } from '../../enums/EnumButtonSize';
import { Subject } from 'rxjs';

export interface ICard {
  imageUrl: string;
  title: string;
  subtitle: string;
  linkUrl: string;
  buttonEvent: Subject<void>
}
const Card = (props: ICard) => {
  const { linkUrl, title, subtitle, imageUrl, buttonEvent } = props;

  return (
    <div className="Card">
      <a href={linkUrl}>
        <div className="Card__image" style={{ backgroundImage: `url(${imageUrl})` }} />
      </a>
      <div className="Card__content">
        <h3 className="Card__title" style={{ alignSelf: "center" }} >
          {title}
        </h3>
        <p className="Card__subtitle" >
          {subtitle}
        </p>
      </div>
      <div style={{ width: '54px' }}>
        <Button
          event={buttonEvent}
          notPrevent={true}
          text="Go"
          type={EnumButtonType.SecondaryAction}
          size={EnumButtonSize.auto}
          url={`${linkUrl}/`} />
      </div>
    </div>
  );
}

export default Card;