import { useEffect } from 'react';
import { Subscription } from 'rxjs';

function useSubscriptionsManager(initialState: Subscription[]): (...s: Subscription[]) => void {
  let subscriptions = initialState;

  function addSubscriptions(...subs: Subscription[]) {
    subscriptions = subscriptions.concat(...subs);
  }

  function cleanUp() {
    subscriptions.forEach(x => x.unsubscribe());
  };

  useEffect(() => {
    return cleanUp;
  });

  return addSubscriptions;
}

export default useSubscriptionsManager;