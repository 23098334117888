import React from 'react';
import './ResourceList.scss';
import CommonUtils from '../../../common/utils';
import { Link } from 'gatsby';
import { ICard } from '../../../common/components/Card/Card';
import CardList from '../../../common/components/Card/List/List';
import { Constants } from '../../../common/constants';
import useSubscriptionsManager from '../../../common/hooks/subscriptions';
import trackEvent from '../../../common/services/analytics.service';

export interface IResourceList {
  subheader?: string;
  list: ICard[];
  description?: string;
  backToHome?:boolean;
  viewAll?:boolean;
  title?: string;
}

const ResourceList = (props: IResourceList) => {
  const { subheader, list, description, backToHome, viewAll, title } = props;
  const titleComponent = title ?
    <h1 className="FeaturedResources__title">
      {CommonUtils.contentSanitize(title)}
    </h1> :
    <></>;
  const subheaderComponent = subheader ?
    <h1 className="FeaturedResources__subheader">
      {CommonUtils.contentSanitize(subheader)}
    </h1> :
    <></>;
  const descriptionComponent = description ?
    <p className="FeaturedResources__description">{description}</p> :
    <></>;
  const subs = list.map(x => x.buttonEvent?.subscribe({
    next: () => {
      trackEvent('', {
        category: Constants.Segment.Category.Resources,
        action: x.title,
        label: window.location.pathname
      });
    }
  }));
  useSubscriptionsManager(subs);

  const trackBackToHome = () => {
    trackEvent(Constants.Segment.Action.BackToHome, {
      action: Constants.Segment.Action.BackToHome,
      label: window.location.pathname,
      category: Constants.Segment.Category.Navigation
    })
  }

  const trackViewAll = () => {
    trackEvent(Constants.Segment.Action.ViewAll, {
      category: Constants.Segment.Category.Navigation,
      label: window.location.pathname,
      action: Constants.Segment.Action.ViewAll
    });
  }

  const backToHomeComponent = backToHome ?
    <p className="FeaturedResources__back">
      <Link to='/' onClick={trackBackToHome}>&lt; Back to home</Link>
    </p> : <></>;

  const viewAllComponent = viewAll ?
    <p className="FeaturedResources__viewAll">
      <Link to='/resources/' onClick={trackViewAll}>View all &gt;</Link>
    </p> : <></>;

  return (
    <div className="FeaturedResources">
      {viewAllComponent}
      {titleComponent}
      {subheaderComponent}
      {descriptionComponent}
      {backToHomeComponent}
      <CardList list={list} />
    </div>
  );
};

export default ResourceList;