import parse from 'html-react-parser';
import * as validator from "email-validator";

export type JSXChildren = JSX.Element | JSX.Element[];

class CommonUtils {
  static contentSanitize(content: string): JSXChildren {
    return parse(content, {
      trim: true,
      replace: (element) => {
        // This method allows to clean up/modify the incoming code from the WP content.
        // For example removing the WP classes.
        if (element?.attribs?.class) delete element.attribs.class;
        return element;
      }
    });
  }

  static buildClassesString(classesObject?: {}): string {
    if (!classesObject) return '';

    const classes = new Map<string, boolean>(Object.entries(classesObject));
    const classesString: string[] = [];

    classes.forEach((value, key) => {
      if (value) classesString.push(key);
    });

    return classesString.join(' ');
  }

  static isNull<T>(val: T) {
    return val === null;
  }

  static isUndefined<T>(val: T) {
    return val === undefined;
  }

  static isStringEmpty(str: string) {
    return str === "";
  }

  static isEmailValid(str: string) {
    return validator.validate(str);
  }

  static clone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  static isBrowser = typeof window !== 'undefined';

  static getWindowVerticalScrollPosition() {
    if (!CommonUtils.isBrowser) return 0;
    return window.scrollY;
  };

  static getScrollableWindowHeight() {
    return document.body.scrollHeight - window.innerHeight;
  };

  static getScrolledPercentage(verticalPosition: number) {
    return (verticalPosition * 100) / CommonUtils.getScrollableWindowHeight();
  }
}

export default CommonUtils;