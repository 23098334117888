import React from 'react';
import './Button.scss';
import { IButton } from '../../models/button';
import { EnumButtonType } from '../../enums/EnumButtonType';
import CommonUtils from '../../utils';
import { EnumButtonSize } from '../../enums/EnumButtonSize';
import { Link } from 'gatsby';

function Button<T>(props: IButton<T>) {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    if (!props.notPrevent) {
      e.preventDefault();
    }

    if (props.event) {
      props.event.next();
    }
  };

  const buttonClasses = {
    'Button': true,
    'Button__primary': CommonUtils.isUndefined(props.type) || props.type === EnumButtonType.Primary,
    'Button__secondary': props.type === EnumButtonType.Secondary,
    'Button__primary-action': props.type === EnumButtonType.PrimaryAction,
    'Button__secondary-action': props.type === EnumButtonType.SecondaryAction,
    'Button__small': props.size === EnumButtonSize.small,
    'Button__normal': CommonUtils.isUndefined(props.size) || props.size === EnumButtonSize.normal,
    'Button__auto': props.size === EnumButtonSize.auto
  };

  return !props.asAction ?
    <Link className={CommonUtils.buildClassesString(buttonClasses)}
      to={props.url || ''}
      onClick={handleClick}>
      {props.text}
    </Link> :
    <a
      href={props.url || ''}
      className={CommonUtils.buildClassesString(buttonClasses)}
      onClick={handleClick}
    >
      {props.text}
    </a>;
}

export default Button;
