import React from 'react';
import './Container.scss';
import { JSXChildren } from '../../utils';

const Container = (props: { children: JSXChildren }) => {
  return (
    <div className="Container">
      {props.children}
    </div>
  );
};

export default Container;