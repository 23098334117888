import React from 'react';
import './List.scss';
import Card, { ICard } from '../Card';
import { isMobile, isTablet } from 'react-device-detect';

interface ICardList {
  list: ICard[]
}

const CardList = (props: ICardList) => {
  const list = props.list.map((x, i) => <Card key={i} {...x} />);
  return (
    <div className="CardList">
      {list}
    </div>
  );
};

export default CardList;
